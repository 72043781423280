import {
  SeniorityLevel,
  EducationLevel,
  EmploymentType,
  EventType,
  InterestLevel,
  RemoteOption,
  Industry,
  PeriodRange,
  JobStatus,
  JobPostStatus,
} from '../generated/types';

type enumKeyType =
  | 'SeniorityLevel'
  | 'EducationLevel'
  | 'EmploymentType'
  | 'EventFormat'
  | 'InterestLevel'
  | 'RemoteOption'
  | 'Industry'
  | 'PeriodRange'
  | 'JobPostStatus'
  | 'JobStatus';
export function convertEnumToString(
  enumKey: enumKeyType,
  enumType:
    | SeniorityLevel
    | EducationLevel
    | EmploymentType
    | EventType
    | InterestLevel
    | RemoteOption
    | Industry
    | PeriodRange
    | JobStatus
    | JobPostStatus
): string {
  switch (enumKey) {
    case 'Industry':
      switch (enumType) {
        case Industry.AccountingFinance:
          return 'Accounting & Finance';
        case Industry.Administrative:
          return 'Administrative';
        case Industry.ArtDesign:
          return 'Art & Design';
        case Industry.BusinessSales:
          return 'Business & Sales';
        case Industry.Construction:
          return 'Construction';
        case Industry.Education:
          return 'Education';
        case Industry.Engineering:
          return 'Engineering';
        case Industry.Entertainment:
          return 'Entertainment';
        case Industry.GovernmentPublicAdministration:
          return 'Government & Public Administration';
        case Industry.HealthcareServices:
          return 'Healthcare Services';
        case Industry.HospitalityTourism:
          return 'Hospitality & Tourism';
        case Industry.HumanResources:
          return 'Human Resources';
        case Industry.InformationTechnology:
          return 'Information Technology';
        case Industry.Legal:
          return 'Legal';
        case Industry.Management:
          return 'Management';
        case Industry.Manufacturing:
          return 'Manufacturing';
        case Industry.Marketing:
          return 'Marketing';
        case Industry.Other:
          return 'Other';
        case Industry.RetailCustomerService:
          return 'Retail & Customer Service';
        case Industry.ScienceResearch:
          return 'Science & Research';
        case Industry.SoftwareEngineering:
          return 'Software Engineering';
        case Industry.TransportationLogistics:
          return 'Transportation & Logistics';
      }
      break;

    case 'JobPostStatus':
      switch (enumType) {
        case JobPostStatus.Draft:
          return 'Draft';
        case JobPostStatus.PendingReview:
          return 'Pending Review';
        case JobPostStatus.Approved:
          return 'Approved';
        case JobPostStatus.Published:
          return 'Published';
        case JobPostStatus.Unpublished:
          return 'Unpublished';
      }
      break;

    case 'JobStatus':
      switch (enumType) {
        case JobStatus.Scrapped:
          return 'To Be Reviewed';
        case JobStatus.Wishlist:
          return 'Wishlist';
        case JobStatus.Applied:
          return 'Applied';
        case JobStatus.Interview:
          return 'Interviewing';
        case JobStatus.Offer:
          return 'Offer';
        case JobStatus.Accepted:
          return 'Accepted';
        case JobStatus.Closed:
          return 'Closed';
      }
      break;

    case 'SeniorityLevel':
      switch (enumType) {
        case SeniorityLevel.Entry:
          return 'Entry';
        case SeniorityLevel.Junior:
          return 'Junior';
        case SeniorityLevel.Mid:
          return 'Mid';
        case SeniorityLevel.Senior:
          return 'Senior';
        case SeniorityLevel.Lead:
          return 'Lead';
        // case SeniorityLevel.Manager:
        //   return 'Manager';
        // case SeniorityLevel.Executive:
        //   return 'Executive';
        // case SeniorityLevel.Director:
        //   return 'Director';
      }
      break;

    case 'InterestLevel':
      switch (enumType) {
        case InterestLevel.JustBrowsing:
          return 'Just Browsing';
        case InterestLevel.OpenToOpportunity:
          return 'Open to Opportunity';
        case InterestLevel.ActivelyLooking:
          return 'Actively Looking';
      }
      break;

    case 'PeriodRange':
      switch (enumType) {
        case PeriodRange.Day:
          return 'Today';
        case PeriodRange.Week:
          return 'This week';
        case PeriodRange.Month:
          return 'This Month';
        case PeriodRange.Overdue:
          return 'Overdue';
        case PeriodRange.Year:
          return 'Year';
      }
      break;

    case 'EventFormat':
      switch (enumType) {
        case EventType.Face2Face:
          return 'Face to Face';
        case EventType.PhoneCall:
          return 'Phone Call';
        case EventType.VideoCall:
          return 'Video Call';
        case EventType.Other:
          return 'Other';
      }
      break;

    case 'EducationLevel':
      switch (enumType) {
        case EducationLevel.ALevelsGnvq:
          return 'A levels /advanced GNVQ';
        case EducationLevel.Btec:
          return 'BTEC';
        case EducationLevel.CityGuilds:
          return 'City & Guilds';
        case EducationLevel.Diploma:
          return 'Diploma';
        case EducationLevel.GcseGnvqOLevels:
          return 'GCSE/GNVQ/O levels';
        case EducationLevel.HndHnc:
          return 'HND/HNC';
        case EducationLevel.MasterDegreeOrHigher:
          return 'Masters degree or higher';
        case EducationLevel.Phd:
          return 'PhD';
        case EducationLevel.SeniorBusinessTechQualification:
          return 'Senior business or technical qualification';
        case EducationLevel.UniversityDegree:
          return 'University degree';
      }
      break;

    case 'EmploymentType':
      switch (enumType) {
        case EmploymentType.Contract:
          return 'Contact (freelance)';
        case EmploymentType.Internship:
          return 'Internship';
        case EmploymentType.PermanentFullTime:
          return 'Permanent Full Time';
        case EmploymentType.PermanentPartTime:
          return 'Permanent Part Time';
        case EmploymentType.Temporary:
          return 'Temporary';
      }
      break;

    case 'RemoteOption':
      switch (enumType) {
        case RemoteOption.Flexible:
          return 'Flexible Remote';
        case RemoteOption.FourDays:
          return 'Four days Remote';
        case RemoteOption.ThreeDays:
          return 'Three days Remote';
        case RemoteOption.TwoDays:
          return 'Two days Remote';
        case RemoteOption.OneDay:
          return 'One day Remote';
        case RemoteOption.FullyRemote:
          return 'Fully Remote';
        case RemoteOption.NoRemote:
          return 'No Remote';
      }
      break;
  }
  return enumKey;
}
